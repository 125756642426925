import React, {useEffect, useState} from 'react'
import GraphQLErrorList from '../../../components/graphql-error-list'

import Container from '../../../components/container'
import Seo from '../../../components/seo'
import Heading from '../../../components/heading'
import Layout from '../../../containers/layout'
import Summer2020 from '../../../components/summer-2020'
import Summer2020Form from '../../../components/summer-2020-form'

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout isHome>
      <Seo />
      <Container>
        <Heading ja='NY育英サマースクール&キャンプ2020' en='Summer School & Camp 2020' />
        <Summer2020 />
        <Summer2020Form isAll />
      </Container>
    </Layout>
  )
}

export default IndexPage
